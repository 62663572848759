import buxon from '../assets/png/buxon.png'
import madville from '../assets/png/madville.png'
import store from '../assets/png/store.png'
import madvillemint from '../assets/png/madvillemint.png'
import stake from '../assets/png/stake.png'
import raffle from '../assets/png/raffle.png'
import bmarket from '../assets/png/bmarket.png'
import buxons from '../assets/png/buxons.png'
import slots from '../assets/png/slots.png'
import CVDC from '../assets/png/CVDC.png'
import germination from '../assets/png/germination.png'
import gwtrailers from '../assets/png/gwtrailers.png'
import sillybot from '../assets/png/sillybot.png'


export const blogData = [
    {
        id: 1,
        title: 'Crypto Trading Bot',
        description: 'An automated crypto trading bot that uses webhooks requests to execute trades on supported exchanges.',
        date: 'September, 2021',
        image: sillybot,
        url: 'https://sillybot.vercel.app/'
    },
    {
        id: 2,
        title: 'GW Trailers',
        description: 'The home page for a trailer rental company.',
        date: 'August, 2022',
        image: gwtrailers,
        url: 'https://gwtrailers.vercel.app/'
    },
    {
        id: 3,
        title: 'Madville Villinz Staking',
        description: 'A staking WebApp for the Madville Villinz Project with VLNZ token integration.',
        date: 'July, 2022',
        image: stake,
        url: 'https://madville-staking.vercel.app/'
    },
    {
        id: 4,
        title: 'Solana NFT Storefront',
        description: 'Created the design for a Solana NFT storefront.',
        date: 'December, 2022',
        image: store,
        url: 'https://madvillestore.vercel.app/'
    },
    {
        id: 5,
        title: 'Madville Villinz  Mint',
        description: 'A mint WebApp for the Madville Villinz Project.',
        date: 'July, 2022',
        image: madvillemint,
        url: 'https://madville-mint.vercel.app/'
    },
    {
        id: 6,
        title: 'Madville Villinz Raffle',
        description: 'A Raffle WebApp for the Madville Villinz Project with VLNZ token integration.',
        date: 'September, 2022',
        image: raffle,
        url: 'https://madvilleraffle.vercel.app/'
    },
    {
        id: 7,
        title: 'Madville Villinz  NFT Project',
        description: 'Worked with a Vegas DJ to bring his art to life on the Solana Blockchain and continued to support the project with Staking and raffle platforms. Conducted various airdrops, created the VLNZ token and integrated it into the apps.',
        date: 'July, 2022',
        image: madville,
        url: 'https://madvillevillinz.com/'
    },
    {
        id: 8,
        title: 'Buxons Marketplace',
        description: 'A marketplace for Buxons NFTs.',
        date: 'March, 2022',
        image: bmarket,
        url: 'https://buxon-storefront.vercel.app/#/'
    },
    {
        id: 9,
        title: 'Buxons Staking',
        description: 'A staking WebApp for the Buxons Project with BUXX token integration.',
        date: 'March, 2022',
        image: buxons,
        url: 'https://buxonstaking.vercel.app/'
    },
    {
        id: 10,
        title: 'Buxons Slots',
        description: 'A slot machine WebApp for the Buxons Project with BUXX token integration.',
        date: 'March, 2022',
        image: slots,
        url: 'https://buxonslot.vercel.app/'
    },
    {
        id: 11,
        title: 'Metajuana NFT Project',
        description: 'In charge of the staking and Evolution of the metajuana project. After staking the NFT and earning enough of the native token, the users were able to upgrade their seed NFT into a 2D plant NFT and later a 3D plant.',
        date: 'July, 2022',
        image: germination,
        url: 'https://old-metajuana.vercel.app/'
    },
    {
        id: 12,
        title: 'Metajuana Project',
        description: 'In charge of the staking and Evolution of the metajuana project. After staking the NFT and earning enough of the native token, the users were able to upgrade their seed NFT into a 2D plant NFT and later a 3D plant.',
        date: 'July, 2022',
        image: germination,
        url: 'https://old-metajuana.vercel.app/'
    },
    {
        id: 13,
        title: 'Buxons NFT Project',
        description: 'Ran the development of a Solana NFT project which included various web3 apps such as a minting app, staking app, raffles site, slot machine, and more. Created a custom cryptocurrency to integrate into these apps.',
        date: 'September, 2021',
        image: buxon,
        url: 'https://buxonwebsite.vercel.app/'
    },
    {
        id: 14,
        title: 'CVDC Mint',
        description: 'A mint page for the CVDC project.',
        date: 'August, 2022',
        image: CVDC,
        url: 'https://cvdcmint.vercel.app/'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/